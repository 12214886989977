<template>
  <div class='procedure-editor'>
    <flash-message></flash-message>
    <div class='procedure__viewer'>
      

      <div class='procedure__content'>


        <article v-if='showDescription && !showEdit' class='procedure__description ck-content' v-html="procedure.description"></article>

        <div class='procedure__toolbar'>
          <div class='button-group' v-if='can'>

            <template v-if='!showEdit'>
              <a href='#' @click.prevent.stop='toggleEdit' class='button button--small button--secondary'><i class='fas fa-edit'></i>Go to Edit Mode</a>
              <a href='#' v-if='!showDescription && procedure.description' @click.prevent.stop='toggleDescription' class='button button--small button--secondary'><i class='fas fa-info-circle'></i>Show Process Description</a>
              <a href='#' v-if='showDescription && procedure.description' @click.prevent.stop='toggleDescription' class='button button--small button--secondary'><i class='fas fa-info-circle'></i>Hide Process Description</a>
              <template v-if='!turn_off_completion'>
                <a href='#' v-if='procedure.completed > 0' class='button button--small button--secondary' @click.prevent.stop='copyCompletedTasks'><i class='fas fa-copy'></i>Copy Completed Tasks ({{procedure.completed}})</a>
                <slot></slot>
              </template>
            </template>

            
            <template v-if='showEdit'>
              <a href='#' @click.prevent.stop='toggleEdit' class='button button--small button--secondary'><i class='fas fa-times'></i>Cancel Edit Mode</a>
              <a href='#' v-if='tasksLength > 0' @click.prevent.stop='toggleSort' class='button button--small button--secondary'><i class="fas fa-cog"></i>Rearrange or Delete Tasks</a>
              <a href='#' @click.prevent.stop='submitForm' class='button button--primary'><i class="fas fa-save"></i>Save Changes</a>
            </template>
          </div>
          <div v-if='!can'></div>
        </div>

        <div v-if='showBulk' class='procedure__bulk'>
          <textarea v-model='bulk' placeholder='Add each task on a separate line...'></textarea>
          <a href='#' class='button button--primary' @click.prevent.stop='convertBulk'>Convert to Tasks</a>
        </div>

        <div class='procedure__form' v-if='showEdit'>
          <div class='task-sorter' v-if='showSort'>
            <div style='display: inline-block;'>
              <a href='#' @click.prevent.stop='toggleSort' class='button button--secondary'><i class='fas fa-times'></i>Close</a>
            </div>
            <p>Drag and drop tasks to rearrange...</p>
            <draggable v-model="procedure_tasks_attributes" @end="onSortEnd">
              <transition-group>

                <div class='task-sorter__task' v-show='!task._destroy' :key="'drag' + index" v-for='(task, index) in procedure_tasks_attributes'>
                  <h1><i class='fas fa-bars'></i> {{task.name}}</h1>
                  <a @click.prevent.stop='removeTask(index, task.id)' href='#' class='button button--danger button--small'><i class="fas fa-trash"></i>Delete Task</a>
                </div>
              </transition-group>
            </draggable>
          </div>
          <form class='form' v-if='!showSort' @submit.prevent.stop="submitForm">
            <div class='form-group'>
              <label for='procedure_name' class='required'>Give your process a name</label>
              <input type='text' required id='procedure_name' v-model='name' placeholder='Our Standard Employee Termination Procedure'>
            </div>

            <div class='form-group'>
              <label for='procedure_description'>Describe what will be accomplished at the end</label>

              <writer :record_id='id' record_type='Procedure'></writer>
            </div>

            <div class='procedure-editor__procedure' v-show='!task._destroy' v-for='(task, index) in procedure_tasks_attributes'>
              <div class='procedure-editor__box'>
                <aside>{{index + 1}}</aside>
                <main>
                  <div class='form-group'>
                    <label class='required' :for='"task" + index' placeholder=''>Task Name</label>
                    <input type='text' :id='"task" + index' required :value='task.name' @input='e => updateTaskName(index, e)'>
                  </div>
                  <div class='procedure-editor__opendesc' v-if='openProcessEdit != index'>
                    <a href='#' class='button button--secondary'  @click.prevent.stop='toggleOpenProcessEdit(index)'>Add/Edit Description of Task</a>
                  </div>
                  <div class='form-group' v-if='task && openProcessEdit === index'>
                    <label>Description of Task</label>

                    <writer :record_id='id' record_type='Procedure' :description="task.description" :index='index'></writer>
                    
                  </div>
                </main>
              </div>
            </div>
            <div class='button-group' v-if='can'>
              <a href='#' @click.prevent.stop="addTask" class='button button--large button--success'><i class='fas fa-plus'></i>New Task</a>
              <a href='#' v-if='tasksLength == 0' @click.prevent.stop='toggleBulk' class='button button--large button--success'><i class="fas fa-plus-circle"></i>Bulk Add</a>
            </div>
          </form>
        </div>
        <div v-if='!showEdit'>
          <div v-show='procedure.procedure_tasks_attributes.length > 0'>
            <div :class='{"procedure": true, "procedure--full": turn_off_completion, "procedure--completed": task.completed && !turn_off_completion}' v-for='(task, index) in procedure.procedure_tasks_attributes' v-if='task.name.length > 0'>
              <aside v-if='!turn_off_completion'>
                <input type='checkbox' v-if='can' :checked='task.completed' @input="check(task.id, index, task.completed)">
                
              </aside>
              <main>
                <h1 class='procedure__title'><a href='#'  v-if='task.description.length > 0' @click.prevent.stop='toggleOpenProcessView(openProcessView == index ? -1 : index)'>{{task.name}}</a><span v-if='task.description.length == 0'>{{task.name}}</span></h1>
              </main>

              <div class='procedure__open ck-content' v-if='openProcessView === index'>
                <a href='#' @click.prevent.stop='toggleOpenProcessView(-1)'><i class="fas fa-chevron-up"></i> Collapse Task Description</a>
                <div v-html='task.description'></div>
              </div>
              
              <div v-show='task.completed && !turn_off_completion' class='procedure__complete'><i class='fas fa-check'></i> Task Completed on {{task.completed_date}} by {{task.user_name}}
              </div>
            </div>
          </div>
          <div class='procedure__blank' v-show='procedure.procedure_tasks_attributes.length == 0 && !showBulk'>Are you procrastinating? Turn on Edit Mode, add some tasks, and get stuff done.</div>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script>
const axios = require('axios');
import draggable from 'vuedraggable'
import Writer from './process_writer.vue';
import { mapState, mapGetters } from 'vuex'

export default {
  props: ["url", "can", "turn_off_completion", "id"],
  components: {
    draggable,
    Writer
  },
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'showBulk': 'showBulk',
      'showSort': 'showSort',
      'procedure': 'procedure',
      'showDescription': 'showDescription',
      'openProcessView': 'openProcessView',
      'openProcessEdit': 'openProcessEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength'
    }),
    name: {
      get() {
        return this.$store.state.procedures.procedure.name
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureName', value)
      }
    }, 
    description: {
      get() {
        return this.$store.state.procedures.procedure.description
      },
      set(value) {
        this.$store.dispatch('procedures/updateProcedureDescription', value)
      }
    },
    bulk: {
      get() {
        return this.$store.state.procedures.bulk
      },
      set(value) {
        this.$store.dispatch('procedures/updateBulk', value)
      }
    },
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  mounted () {
    this.$store.dispatch('procedures/setState', [this.url, this.can, this.template])
    this.$store.dispatch('procedures/fetch');
  },
  methods: {
    updateTaskName(index, e) {
      this.$store.dispatch('procedures/updateTaskName', [index, e.target.value]);
    },
    convertBulk() {
      this.$store.dispatch('procedures/convertBulk');
    },
    onSortEnd () {
      this.$store.dispatch('procedures/resort');
    },
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
    toggleBulk () {
      this.$store.dispatch('procedures/toggleBulk');
    },
    toggleDescription () {
      this.$store.dispatch('procedures/toggleDescription');
    },
    toggleOpenProcessView (index) {
      this.$store.dispatch('procedures/toggleOpenProcessView', index);
    },
    toggleOpenProcessEdit (index) {
      this.$store.dispatch('procedures/toggleOpenProcessEdit', index);
    },
    toggleSort () {
      this.$store.dispatch('procedures/toggleSort');
    },
    check (id, index, completed) {
      this.$store.dispatch('procedures/check', [id, index, completed]);
    },
    addTask () {
      this.$store.dispatch('procedures/addTask');
      this.flash("New Task Added!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    },
    removeTask (index, id) {
      this.$store.dispatch('procedures/removeTask', [index, id]);
    },
    copyCompletedTasks () {

      if (this.procedure.completed == 0) {
        return alert("Complete tasks first!")
      }
      var that = this;
      var text = "";
      that.procedure.procedure_tasks_attributes.forEach ( function (item, idx) {
        if (item.completed ) {
          text += "✔ COMPLETED - " + item.name; 
          if (idx != that.procedure.procedure_tasks_attributes.length - 1) {
            text += "\n";
          }
        }
      })
      Clipboard.copy(text);

      that.flash("Copied!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
      text = "";
    },
    submitForm () {
      this.$store.dispatch('procedures/submitForm');
    }
  }
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

