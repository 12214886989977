<template>
  <div style='position: relative;'>
    <a href='#' class='button button--secondary' @click.prevent.stop="isShown = true">Duplicate</a>
    <transition name='popopen'>
      <div class='duplicator' v-if='isShown'>
        <span><b>Create duplicate:</b></span>
        <div class='button-group'>
          <slot></slot>
        </div>
        <a href='#' class='button button--secondary' @click.prevent.stop="isShown = false">Cancel</a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: [""],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

