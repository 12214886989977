<template>
  <div class="wizard__container">
    <h2 class='form__heading'>Edit with JSON</h2>
    <h2 class='form__subheading'>Paste in JSON to customize your Asset Layout.</h2>
    <div class='json'>
      <textarea v-model='jsonFormat'></textarea>
      <a href='#' @click.prevent.stop="parseJson">Parse</a>
    </div>
  </div>
</template>

<script>

    import _ from 'lodash'

    export default {
        props: ["asset_layout"],
        name: "advanced",
        methods: {
            parseJson () {
                var obj = {}
                    try {
                        obj = JSON.parse(this.jsonFormat);
                    } catch (e) {
                        alert("The JSON failed to validate:" + e)
                        return false;
                    }
                    this.asset_layout = obj;

                    //this.transformedJSON = newValue;
            }
        },
        computed: {
            jsonFormat () {
                let json = _.cloneDeep(this.asset_layout)

                json.fields.forEach(function(element, index) {
                    delete element["id"]
                    if (element["is_destroyed"] === true) {
                        json.fields.splice(index, 1);
                    }
                });
                return JSON.stringify(json, null, 4);
            }
        }
    }
</script>

<style scoped>

</style>