<template>
  <div style='position: relative;'>
    <a href='#' class='button button--secondary' v-if='button == "Share"' @click.prevent.stop="isShown = true"><i class='fas fa-share'></i>{{button}}</a>
    <a href='#' class='button button--secondary' v-if='button != "Share"' @click.prevent.stop="isShown = true">{{button}}</a>
    <transition name='popopen'>
      <div class='sharer' v-if='isShown'>
        <span><b>{{text}}</b></span>
        <slot></slot>
        <a href='#' class='button--close button button--danger' @click.prevent.stop="isShown = false"><i class='fas fa-times'></i></a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["button", "text"],
  mounted () {
    
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

