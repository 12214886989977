<template>

  <div>

    <div class="wizard__container">
      <h2 class='form__heading'>Design & Layout</h2>
      <div class='form-group'>
        <label for='' class='required'>Name</label>
        <input type='text' id='name' v-model='asset_layout.name'>
      </div>

      <div class='switchs close' style='margin-bottom: 1.5rem'>
        <div>
          <label for="checkboxActive" class='switch-contain'>
            <div class='switch'>
              <input id='checkboxActive' v-model="asset_layout.active" type='checkbox'>
              <div class="checkslider round"></div>
            </div>
            Activate Asset Layout
          </label>
        </div>
      </div>

      <div class='switchs close' style='margin-bottom: 1.5rem'>
        <div>
          <label for="checkboxIncludePasswords" class='switch-contain'>
            <div class='switch'>
              <input id='checkboxIncludePasswords' v-model="asset_layout.include_passwords" type='checkbox'>
              <div class="checkslider round"></div>
            </div>
            Tab for passwords
          </label>
        </div>
      </div>
      <div class='switchs close' style='margin-bottom: 1.5rem'>
        <div>
          <label for="checkboxIncludeComments" class='switch-contain'>
            <div class='switch'>
              <input id='checkboxIncludeComments' v-model="asset_layout.include_comments" type='checkbox'>
              <div class="checkslider round"></div>
            </div>
            Tab for comments
          </label>
        </div>
      </div>
      <div class='switchs close' style='margin-bottom: 1.5rem'>
        <div>
          <label for="checkboxIncludeFiles" class='switch-contain'>
            <div class='switch'>
              <input id='checkboxIncludeFiles' v-model="asset_layout.include_files" type='checkbox'>
              <div class="checkslider round"></div>
            </div>
            Tab for files
          </label>
        </div>
      </div>

      <design :asset_layout="asset_layout" :bg-style="bgStyle" :icon-color-style="iconColorStyle" :icons="icons"/>

      <div class='form-group'>
        <label for='password_types'>Edit Password Types for this Asset Layout</label>
        <textarea id='password_types' style='min-height: 250px' v-model='asset_layout.password_types'></textarea>
        <span class='hint'>One option per line</span>
      </div>
    </div>
  </div>
</template>

<script>
  import design from './design';

    export default {
        props: ["asset_layout", "bg-style", "icon-color-style", "icons", "slide"],
        components: {
            design
        },
        name: "setup"
    }
</script>

<style scoped>

</style>