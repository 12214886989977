<template>
  <div>
    <div style='display: inline-block'>
      <a v-if='!isDecrypted' href='#' class='button button--secondary' @click.prevent.stop='reveal'><i class='fas fa-eye'></i>Reveal Secure Note</a>
    </div>
    <pre v-if='isDecrypted' v-html='note'></pre>
  </div>
</template>

<script>
const axios = require('axios');
var CryptoJS = require("crypto-js");

export default {
  props: ["encrypted_message", "the_key", "reveal_url"],
  data () {
    return {
      note: "",
      isDecrypted: false
    }
  },
  methods: {
    reveal () {
      var that = this;

      var encrypted_message = that.encrypted_message;
      var myPassword = that.the_key;
      
      that.$api.post(that.reveal_url)
      .then(function (response) {
          var decrypted_message = CryptoJS.AES.decrypt(encrypted_message, myPassword);
          var note = decrypted_message.toString(CryptoJS.enc.Utf8)
          console.log(note)
          that.note = note
          that.isDecrypted = true
        })
    }
  }
}
</script>