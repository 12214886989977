<template>
  <div>
    <flash-message></flash-message>
    <div class='drafter'>
      <div class='drafter__finish'>
        <folder-chooser v-on:changeFolder="folderWasChanged" name='article[folder_id]' 
          :existing_name="folder_existing_name"
          :value='folder_value' 
          :folders='folders'></folder-chooser>
        <div class='drafter__publish'>
          <a @click.prevent.stop='publish' class='button button--lg button--primary' href='#'><i class="fas fa-pen-alt"></i>Publish</a>
        </div>
        <a class='button button--secondary' :href='article_url'><i class="fas fa-long-arrow-alt-left"></i>Cancel</a>
      </div>
      <div class='drafter__content'>
        <div class='writer-wrap writer--article'>
          <input type='hidden' :value='title' name='article[name]'>
          <div class='drafter__autosave'>
            <h3 v-if='restored && has_draft'><a href='#'  @click.prevent.stop='unrestore'>Restored from draft - revert to live version</a></h3>
            <h3 v-if='last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_saved}}</span><a @click.prevent.stop='save' href='#' v-if='isDirty'> (Save Now)</a></h3>
            <h3 v-if='!last_saved'><b>Last autosaved:</b> <span class='autosaved-date'>{{last_updated}}</span><a @click.prevent.stop='save' href='#' v-if='isDirty'> (Save Now)</a></h3>
          </div>
          <div v-if='majorError' class='drafter__major-error'>
            <p>An error occurred saving this draft. You may want to copy your changes to an external editor to prevent losing unpublished changes.</p>
          </div>
          <div class='form-group'>
            <label for='articleTitle' class='required'>Title</label>
            <input v-model='title' @input='isDirty = true' required='true' type='text' id='articleTitle' autofocus='true' placeholder='How to...' class='drafter__title'>
          </div>
          <div class='form-group'>
            <label for='editor' class='required'>Content</label>
            <textarea :name='name' id="editor" v-html='precontent'>
            </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>


import '../lib/editor';
import FolderChooser from './folder_chooser.vue';

export default {

  props: ["precontent", "prename", "name", "value",
   "simple", "id", "autosave_url", "has_draft", 
   "draft_title", "draft_content",  "last_saved", "last_updated",
   "article_url", "folder_existing_name", "draft_folder_existing_name", "draft_folder_value", 
   "folders", "folder_value"],
  components: {
    FolderChooser
  },
  mounted () {

    document.title = this.title.length > 0 ? this.title : "Start writing..."
    var that = this;

    ClassicEditor
      .create( document.querySelector( `#editor` ),
        {
          simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: '/public_photos?record_type=Article&record_id=' + that.id,

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRF-TOKEN': 'CSFR-Token',
                Authorization: 'Bearer <JSON Web Token>'
            }
          }
        })
      .then( editor => {
        editor.model.document.on('change:data', (evt, data) => {
          that.content = editor.getData();
          that.isDirty = true
        });
        that.editor = editor;

        if (that.has_draft) {
          that.restore()
          that.restored = true
        }
      } )
      .catch( error => {
          console.error( error );
      } );

      setInterval(function(){ 
        if (that.isDirty) {
          that.save();
        }
      }, that.autosave_seconds);

      document.querySelector("#draftForm").addEventListener("submit", function (e) {
        e.preventDefault();
      });

      window.onbeforeunload = () => (this.isDirty ? true : null);
  },
  watch: {
    title: function (val) {
      document.title = val.length > 0 ? val : "Start writing..."
      this.allowSave = true
    },
    content: function (val) {
      this.allowSave = true
    }
  },
  methods: {
    publish () {
      this.isDirty = false
      document.querySelector("#draftForm").submit()
    },
    restore () {
      this.restored = true;
      this.title = this.draft_title;
      this.editor.setData(this.draft_content);
      this.isDirty = false
    },
    folderWasChanged () {
      this.isDirty = true;
    },
    unrestore () {
      if(confirm('Are you sure?')) {
        this.restored = false;
        this.title = this.prename;
        this.editor.setData(this.precontent);
        this.isDirty = false
        this.useExistingFolder = true
        this.folderValue = this.folder_value;
        this.folderName = this.folder_existing_name;
      }
    },
    save () {
      var that = this;
      that.$api.put(that.autosave_url, { 
        "title" : that.title,
        "content": that.content,
        "folder_id": document.querySelector("#folderIdInput").value
      }).then(function (response) {
        that.isDirty = false;
        var date = response.data.date;
        document.querySelector(".autosaved-date").innerText = date;
        that.majorError = false
        that.flash("Saved!", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      }).catch(error => {
        that.majorError = true
        console.log(error)
        that.flash("Something went wrong!", 'error', 
          {
            timeout: 3000,
            important: true
          }
        );
      })
    }
  },
  data() {
    return {
      majorError: false,
      allowSave: false,
      allowPublish: false,
      editor: null,
      isDirty: false,
      hasChanges: false,
      restored: false,
      title: this.prename,
      content: this.precontent,
      autosave_seconds: 120000 // Every 2 minutes
    }
  }
}
</script>

<style lang='scss'>
.writer--article .ck-editor__editable_inline {
    min-height: 350px;
}
</style>

