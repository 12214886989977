<template>
  <div>
   <vue-good-table
       mode="remote"
  @on-page-change="onPageChange"
  @on-sort-change="onSortChange"
  @on-column-filter="onColumnFilter"
  @on-per-page-change="onPerPageChange"
  :totalRows="totalRecords"
  :isLoading.sync="isLoading"
  :theme='dark_mode ? "nocturnal" : "default"'
  styleClass="vgt-table bordered striped condensed"
  :pagination-options="{
    enabled: true,
    mode: 'records',
    perPage: 50,
    position: 'bottom',
    perPageDropdown: [10, 20, 50, 100, 200],
    dropdownAllowAll: false,
    setCurrentPage: 1,
    nextLabel: 'next',
    prevLabel: 'prev',
    rowsPerPageLabel: 'Items per page',
    ofLabel: 'of',
    pageLabel: 'page', // for 'pages' mode
    allLabel: 'All'
  }"
  :rows="rows"
  :columns="columns">
    <template slot="table-row" slot-scope="props">
    <span v-if="props.column.field == 'name'">
      <a :href='props.row.url'>{{props.row.name}}</a> 
    </span>
    <span v-else>
      {{props.formattedRow[props.column.field]}}
    </span>
  </template>
  </vue-good-table>
  </div>
  
</template>

<script>
import { VueGoodTable } from 'vue-good-table';

export default {
  props: ["options_for_company_type", "options_for_state", "dark_mode"],
  components: {
    VueGoodTable
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
     this.serverParams.sort = {
        type: params[0].type,
        field: params[0].field
      };
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    loadItems() {
      var that = this;

      that.$api.get('c.json', {params: that.serverParams})
      .then(function(response) {
        that.totalRecords = response.data["meta"]["total_results"];
        that.rows = response.data["companies"];
      }).catch(error => {
        alert(error.response.data)
      })
         
    }
  },
  data(){
    return {
      isLoading: false,
      columns: [
        {
          label: 'Name',
          field: 'name',
          html: true,
          width: '25%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter Name', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'Type',
          field: 'company_type',
          html: true,
          width: '25%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter Type', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: this.options_for_company_type, // dropdown (with selected values) instead of text input
          },
        },
        {
          label: 'City',
          field: 'city',
          width: '25%',
          html: true,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter City', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: [], // dropdown (with selected values) instead of text input
              
          },
        },
        {
          label: 'State/Province',
          field: 'state',
          html: true,
          width: '25%',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Filter State/Province', // placeholder for filter input
              filterValue: '', // initial populated value for this filter
              filterDropdownItems: this.options_for_state, // dropdown (with selected values) instead of text input
          },
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '', 
          type: '',
        },
        page: 1, 
        perPage: 50
      }
    };
  },
}
</script>

