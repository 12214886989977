<template>
  <div>
    
  </div>
</template>

<script>

export default {
  props: [],
  mounted () {
    document.getElementById('email').focus();
  }
}
</script>