<template>
  <div>
    <a href='#' class='button button--secondary' @click.prevent.stop='isShown = true'><i class='fas fa-cog'></i>Manage</a>
    <div class='manage_modal__wrapper' v-if='isShown'>
      <div class='manage_modal' v-click-outside="onClickOutside">
        <header>
          <h1><i class='fas fa-cog icon'></i>Manage {{name}}</h1>
          <a href='#' class='button button--secondary' @click.prevent.stop='isShown = false'>Cancel</a>
        </header>
        <slot >
          
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: ["name"],
  mounted () {
    
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    },
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>