<template>
  <div class='wizard'>

    <flash-message></flash-message>


     <header class='wizard__header'>
        

        <div class="wizard__switcher">
          <a href="#" :class="{'selected': slide == 0}" @click.prevent.stop="slide = 0">Edit Design & Layout</a>
          <a href="#" :class="{'selected': slide == 1}" @click.prevent.stop="slide = 1">Edit Fields</a>
        </div>

        <header class='button-group'>
          <input @click.prevent="submitForm" type='submit' class='button button--success button--large' value='Save Changes'>
          <a href='/admin/asset_layouts' class='button button--large button--secondary'>Close & Return</a>
        </header>

        
     </header>

      <ul class='wizard__errors' v-if='errors.length > 0'>
        <li v-for='error in errors'><i class="fas fa-exclamation-triangle"></i>{{error}}</li>
      </ul>

    <setup v-if='slide===0' :asset_layout="asset_layout" :errors="errors"/>

    <fields v-if='slide===1' :asset_layout="asset_layout" :asset_layouts="asset_layouts" />

    <advanced v-if='slide==2' :asset_layout="asset_layout" />
  </div>
</template>

<script>
import axios from 'axios';
import setup from "./setup";
import fields from "./fields";
import advanced from "./advanced";

export default {
  name: 'asset-layouts-editor',
  props: ["url", "asset_layouts"],
  components: {
      setup,
      fields,
      advanced
  },
  mounted () {
    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.asset_layout = data;
    })

    window.onbeforeunload = function(e) {
      return "Sure you want to leave?";
    };
  },
  data: function () {
    return {
      slide: 0,
      errored: false,
      saved: false,
      errors: [],
      asset_layout: {}
    }
  },
  methods: {
    submitForm () {
      var data = JSON.stringify({"asset_layout": this.asset_layout})

      var that = this;

      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
      axios({
        url: that.url,
        method: "PUT",
        headers: headers,
        data: data
      })
      .then((response) => {
        console.log(response);
        that.asset_layout = response.data;
        that.saved = true;

        that.errors = []
        that.flash("Asset Layout Updated", 'success', 
          {
            timeout: 3000,
            important: true
          }
        );
      })
      .catch((error) => {
        that.flash('Something went wrong!', 'error', 
          {
            timeout: 3000,
            important: true
          }
        );
        that.errors = error.response.data
      })
    }

  }
}
</script>


