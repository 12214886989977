<template>
  <div class='writer-wrap'>
    <div :id="'editor' + index">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ["index", "record_id", "record_type"],
  computed: {
    description () {
      if (this.index != null) {
        return this.$store.state.procedures.procedure.procedure_tasks_attributes[this.index].description
      } else {
        return this.$store.state.procedures.procedure.description
      }
    }, 
  },
  watch: {
    description (newValue) {
      if (this.editor && newValue !== this.description) {
        this.editor.setData(this.description)
      }
    }
  },
  mounted () {
    var that = this;

    ClassicEditor
      .create( document.querySelector( `#editor${that.index}` ),
        {
          simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: `/public_photos?record_id=${that.record_id}&record_type=${that.record_type}`,

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRF-TOKEN': 'CSFR-Token',
                Authorization: 'Bearer <JSON Web Token>'
            }
          }
        })
      .then( editor => {
        if (this.description) {
          editor.setData(this.description);
        }
        editor.model.document.on('change:data', (evt, data) => {
          var content = editor.getData();

          if (that.index === undefined) {
            this.$store.dispatch('procedures/updateProcedureDescription', content)
          } else {
            this.$store.dispatch('procedures/updateTaskDescription', [that.index, content])
          }
        });
        
      } )
      .catch( error => {
          console.error( error );
      } );
      
  },
  data() {
    return {
      editor: null
    }
  }
}
</script>

<style lang='scss'>
</style>

