<template>
  <div>
    <aside class='wizard__sidebar'>
      <div class='drag-fields'>
        <div class='wizard__field wizard__field--inactive'><h2>Name</h2></div>
        <draggable v-model="asset_layout.fields" @end="onSortEnd">
          <transition-group>
            <div class='wizard__field' v-show='!asset_layout_field.is_destroyed' :key="'drag' + index" v-for="(asset_layout_field, index) in asset_layout.fields">
              <h2><i class="fas fa-ellipsis-v"></i>{{asset_layout_field.label || "Untitled"}}</h2>
              <div class='button-group'>
                <a href='#' class='button button--primary' @click.prevent.stop="scrollMeTo('field' + index)">Edit</a>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>

      <div class='wizard__sidebar__button'>
        <a href='#' class=' button button--success button--primary button--large' @click.prevent="addField">Add New Field</a>
      </div>
    </aside>
    <main class="wizard__main">
      <h2 class='form__heading'>Edit Fields</h2>
      <p v-if="asset_layout.fields && asset_layout.fields.length == 0">There are no fields yet. Add one on the left.</p>
      <div class='form-fields' v-show='!asset_layout_field.is_destroyed' v-for="(asset_layout_field, index) in asset_layout.fields">
        <field :asset_layout="asset_layout" :asset_layout_field="asset_layout_field" :asset_layouts="asset_layouts" :index="index"/>

      </div>
    </main>
  </div>
</template>

<script>

    import draggable from 'vuedraggable'
    import field from './field'

    export default {
        props: ["asset_layout", "asset_layouts"],
        components: {
            draggable,
            field
        },
        name: "fields",
        methods: {
            scrollMeTo(id) {
                var element = document.getElementById(id);
                var top = element.offsetTop - 55;

                window.scrollTo(0, top);
            },
            onSortEnd () {
                this.asset_layout.fields.forEach(function (item, index) {
                    item.position = index + 1
                })
            },
            addField () {

              console.log(this.asset_layouts);
                this.asset_layout.fields.push({
                    id: "",
                    field_type: "Text",
                    label: "Untitled",
                    required: "",
                    hint: "",
                    min: "",
                    max: "",
                    linkable_id: this.asset_layouts[0]["id"],
                    linkable_type: "AssetLayout",
                    options: "",
                    position: this.asset_layout.fields.length + 1,
                    expiration: false,
                    is_destroyed: false
                })
            },
            moveField(index, direction) {

                this.asset_layout.fields[index].position = index;

            }
        }
    }
</script>

<style scoped>

</style>