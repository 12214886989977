<template>
  
  <div class='searcher searcher--mobile' v-click-outside="onClickOutside">

    <div class='searcher__input'>
      <icon></icon>
      <input ref="searchInput" type='search'
        @click.prevent.stop="isShown = true"
        v-debounce:500ms="onInput"
        @keydown.down="onArrowDown"
        @keydown.esc="onClickOutside"
        @keydown.up="onArrowUp"
        @keydown.enter="onEnter" v-model='q' placeholder="Search...">
    </div>

    <div class='searcher__drop' v-if='isShown'>
      <div class='searcher__header'>
       <aside>
          <template v-if='company_id && (isType != "Company")'>
            <a href='#' class='active' v-if='only_company' @click.prevent.stop="changeLimitCompany"><i class='fas fa-briefcase'></i></a>
            <a href='#' v-if='!only_company' @click.prevent.stop="changeLimitCompany"><i class='fas fa-briefcase'></i></a>
          </template>

          <a href='#' v-if='include_archived' class='active' @click.prevent.stop="changeIncludeArchived"><i class='fas fa-university'></i></a>
          <a href='#' v-if='!include_archived' @click.prevent.stop="changeIncludeArchived"><i class='fas fa-university'></i></a>

          <a href='#' v-if='search_by_all' class='active' @click.prevent.stop="changeSearchByAll"><i class="fas fa-search-plus"></i></a>
          <a href='#' v-if='!search_by_all' @click.prevent.stop="changeSearchByAll"><i class="fas fa-search-plus"></i></a>
        </aside>
        <a href='#' class='close' @click.prevent.stop='isShown = false'><i class='fas fa-times'></i></a>
      </div>
      <div class='searcher__columns'>
        
        <section v-if='company_quick_hop'>
          <header>
            <h3><i class='fas fa-briefcase icon'></i>{{structure_name}}</h3>
          </header>
          <main>
            <ul v-if='(company_results.length > 0)' v-show='company_loaded'>
              <li v-for='r in company_results'><a :href='r.url'>{{r.name}}</a></li>
            </ul>
            <div class='index__loading' v-if='!company_loaded'><double-bounce></double-bounce></div>
            <p class='searcher__blank' v-if='company_results.length === 0 && company_loaded'>
            No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
          </main>
        </section>
        <section v-if='!company_quick_hop'>
          <header>
            <h3><i class='fas fa-briefcase icon'></i>{{structure_name}}</h3>
          </header>
          <main>
            <ul v-if='(company_results.length > 0)' v-show='company_loaded && !only_company'>
              <li v-for='r in company_results'><a :href='r.url'>{{r.name}}</a></li>
            </ul>
            <div class='index__loading' v-if='!company_loaded'><double-bounce></double-bounce></div>
            <p class='searcher__blank' v-if='company_results.length === 0 && company_loaded && !only_company'>
            No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            <p class='searcher__blank' v-if='only_company'>No results with filter.</p>
          </main>
        </section>
        <section>
          <header>
            <h3><i class='fas fa-circle icon'></i>Assets</h3>
          </header>
          <main>
            <ul>
              <ul v-if='(asset_results.length > 0)' v-show='asset_loaded'>
                <li v-for='r in asset_results'><a :href='r.url'>{{r.name}}<span>{{r.asset_type}} / {{r.company_name}}</span></a></li>
              </ul>
              <div class='index__loading' v-if='!asset_loaded'><double-bounce></double-bounce></div>
              <p class='searcher__blank' v-if='asset_results.length === 0 && asset_loaded'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            </ul>
          </main>
        </section>
        <section>
          <header>
            <h3><i class='fas fa-key icon'></i>Passwords</h3>
          </header>
          <main>
            <ul>
              <ul v-if='password_results.length > 0' v-show='password_loaded'>
                <li v-for='r in password_results'><a :href='r.url'>{{r.name}}<span><template v-if='r.asset_name'>{{r.asset_name}} / </template>{{r.company_name}}</span></a></li>
              </ul>
              <div class='index__loading' v-if='!password_loaded'><double-bounce></double-bounce></div>
              <p class='searcher__blank' v-if='password_results.length === 0 && password_loaded'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            </ul>
          </main>
        </section>
        <section>
          <header>
            <h3><i class='fas fa-newspaper icon'></i>Articles</h3>
          </header>
          <main>
            <ul>
              <ul v-if='article_results.length > 0' v-show='article_loaded'>
                <li v-for='r in article_results'><a :href='r.url'>{{r.name}}<span v-if='r.company_name'>{{r.company_name}}</span></a></li>
              </ul>
              <div class='index__loading' v-if='!article_loaded'><double-bounce></double-bounce></div>
              <p class='searcher__blank' v-if='article_results.length === 0 && article_loaded'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            </ul>
          </main>
        </section>
        <section>
          <header>
            <h3><i class='fas fa-globe icon'></i>Websites</h3>
          </header>
          <main>
            <ul>
              <ul v-if='website_results.length > 0' v-show='website_loaded'>
                <li v-for='r in website_results'><a :href='r.url'>{{r.name}}<span v-if='r.company_name'>{{r.company_name}}</span></a></li>
              </ul>
              <div class='index__loading' v-if='!website_loaded'><double-bounce></double-bounce></div>
              <p class='searcher__blank' v-if='website_results.length === 0 && website_loaded'>
              No results found. <span v-show='!include_archived'>&nbsp;<a @click.prevent.stop="include_archived = true" href='#'>Check archived results?</a></span></p>
            </ul>
          </main>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../components/icon.vue";
import axios from 'axios';
import {DoubleBounce} from 'vue-loading-spinner'
import vClickOutside from 'v-click-outside'
export default {
  props: ["company_id", "company_name", "structure_name", "company_quick_hop"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    Icon,
    DoubleBounce
  },
  methods: {
    onClickOutside () {
      this.isShown = false;
    },
    changeLimitCompany () {
      this.only_company = !this.only_company;
      this.onInput()
    },
    changeIncludeArchived () {
      this.include_archived = !this.include_archived;
      this.onInput()
    },
    changeSearchByAll () {
      this.search_by_all = !this.search_by_all;
      this.onInput()
    },
    onInput () {

      var that = this;
      that.company_loaded = false;
      that.asset_loaded = false;
      that.password_loaded = false;
      that.article_loaded = false;
      that.website_loaded = false;

      that.isShown = true

      axios.get('/searches', {
        params: {
          q: that.q,
          type: "Company",
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          search_by_all: that.search_by_all
        }
      })
      .then(function (response) {
        that.company_loaded = true;
        that.company_results = response.data;
      })

      axios.get('/searches', {
        params: {
          q: that.q,
          type: "Asset",
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          search_by_all: that.search_by_all
        }
      })
      .then(function (response) {
        that.asset_loaded = true;
        that.asset_results = response.data;
      })

      axios.get('/searches', {
        params: {
          q: that.q,
          type: "Password",
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          search_by_all: that.search_by_all
        }
      })
      .then(function (response) {
        that.password_loaded = true;
        that.password_results = response.data;
      })

      axios.get('/searches', {
        params: {
          q: that.q,
          type: "Article",
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          search_by_all: that.search_by_all
        }
      })
      .then(function (response) {
        that.article_loaded = true;
        that.article_results = response.data;
      })

      axios.get('/searches', {
        params: {
          q: that.q,
          type: "Website",
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          search_by_all: that.search_by_all
        }
      })
      .then(function (response) {
        that.website_loaded = true;
        that.website_results = response.data;
      })
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      if (this.arrowCounter != -1)
        this.$refs["a" + this.arrowCounter][0].click();
    },
    switchType () {
      this.$nextTick(() => this.$refs['searchInput'].focus())
      this.onInput()
    },
  },
  data: function () {
    return {
      funAlert: "",
      company_results: [],
      asset_results: [],
      password_results: [],
      article_results: [],
      website_results: [],
      include_archived: false,
      company_loaded: true,
      asset_loaded: true,
      password_loaded: true,
      article_loaded: true,
      password_loaded: true,
      website_loaded: true,
      q: "",
      search_by_all: false,
      arrowCounter: -1,
      only_company: this.company_id ? true : false,
      results: [],
      isShown: false,
      isType: this.company_id ? "Asset" : "Company",
    }
  }
}
</script>

