<template>
  <div>
    <div class="progress" id="progress">
      <p class="progressbar-text" style='font-size: 1.6rem'></p>
    </div>
  </div>
</template>

<script>

var ProgressBar = require('progressbar.js')


export default {
  props: ["rate", "percentage"],
  data: function () {
    return {
    
    }
  },
  mounted () {
    var that = this;
    window.onload = function onLoad() {
    var circle = new ProgressBar.Circle('#progress', {
        color: '#28a745',
        fill: "#CCC",
        strokeWidth: 3.1,
        duration: 1200,
        easing: 'easeInOut',
        text: {
          value: that.percentage,
          style: {
            // Text color.
            // Default: same as stroke color (options.color)
            color: '#28a745',
            position: 'absolute',
            left: '50%',
            top: '50%',
            fontSize: "29px",
            padding: 0,
            margin: 0,
            // You can specify styles which will be browser prefixed
            transform: {
                prefix: true,
                value: 'translate(-50%, -50%)'
            }
        }
        }
    });

    circle.animate(that.rate);
};
  }
}
</script>


<style type='text/css'>
.progress {
                height: 300px;
            }
            .progress > svg {
                height: 100%;
                display: block;
            }
</style>
