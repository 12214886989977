<template>
   <div class='preview-group'>
    <input type='hidden' name='asset_layout[icon]' :value="icon">
    <input type='hidden' name='asset_layout[icon_color]' :value="icon_color">
    <input type='hidden' name='asset_layout[color]' :value="color">
    <div class='preview'>
      <h2>Preview:</h2>
      <div class='asset-icon big' :style='bgStyle'>
        <i :class='icon' :style="iconColorStyle"></i>
      </div>
    </div>
    <div>
      <div class='form-group-half'>
        <div class='form-group'>
          <label for='' class='required'>Main color</label>
          <input type='color' id='name' v-model='color'>
        </div>
        <div class='form-group'>
          <label for='' class='required'>Icon color</label>
          <input type='color' id='name' v-model='icon_color'>
        </div>
      </div>
    </div>

    <div class='preview__icons'>
      <label :for="'icon' + index" v-for='(i, index) in icons' :class='{"selected": i == icon }'>
        <i :class='i'></i>
        <input type="radio" :id="'icon' + index" :value='i' v-model='icon'>
      </label>
    </div>
  </div>
</template>

<script>
import icons from '../lib/icons.json';
export default {
  props: ["asset_layout"],
  data: function () {
    return {
      icons: icons.icons,
      color: this.asset_layout.color,
      icon: this.asset_layout.icon,
      icon_color: this.asset_layout.icon_color
    }
  },
  computed: {
            borderStyle () {
                return "border: 1px solid " + this.color
            },
            bgStyle () {
                return "background: " + this.color
            },
            iconColorStyle () {
                return "color: " + this.icon_color
            }
        }
}
</script>

