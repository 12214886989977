<template>
  <div>
    <a href='#' @click.prevent.stop="test"><i class="fas fa-vials icon"></i>Test Connection</a>
  </div>
</template>

<script>
export default {
  props: ["url"],
  data () {
    return {
      isShown: false
    }
  },
  methods: {
    test () {
      var that = this;

      that.$api.post(that.url)
      .then(function (response) {
        if (response.data[1] == true) {
         alert("Connection is successful!")
        } else {
          alert("Failed: " + response.data[0])
        }
      })
    }
  }
}
</script>

