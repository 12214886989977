<template>
  <div class='asset-columns__switch'>
    <a href='#' v-if='isSplit == true' @click.prevent.stop='switchSplit'><i class='fas fa-columns'></i></a>
    <a href='#' v-if='isSplit != true' @click.prevent.stop='switchSplit'><i class="fas fa-expand"></i></a>
  </div>
    
</template>

<script>
export default {
  data: function () {
    return {
      isSplit: false
    }
  },
  watch: {
    isSplit: function (newIsSplit, oldIsSplit) {
      var columns = document.querySelector(".asset-columns");
      if (newIsSplit == true) {
        columns.classList.add("asset-columns--full-width")
      } else {
        columns.classList.remove("asset-columns--full-width")
      }
    }
  },
  methods: {
    switchSplit () {

      var columns = document.querySelector(".asset-columns");
      var fullWidthClass = "asset-columns--full-width";

      this.isSplit = !this.isSplit;

      if (columns.classList.contains(fullWidthClass)) {
        columns.classList.add(fullWidthClass)
      } else {
        columns.classList.remove(fullWidthClass)
      }
    }
  }
}
</script>

