<template>
  <div class='index'>

    <flash-message></flash-message>

    <div class='index__filter full'>
      <div class='search search--hitcher'>
        <icon></icon>
        <input type='text'
          @click="showSearch = true"
          v-debounce:100ms="onInput"
          v-model='query' placeholder="Search...">
      </div>
    </div>

    <div class='index__actions' v-if='totalResults > 0 && can'>
      <div class='button-group'>
        <a href='#' v-if='!allSelected' @click.prevent="selectAll" class='button button--small button--secondary'>Select all</a>
        <a href='#' v-if='allSelected' @click.prevent="deselectAll" class='button button--small button--secondary'>Deselect all</a>
        <a href='#' v-if='anySelected && !has_unarchive' @click.prevent="archive" class='button button--small button--secondary'>Archive selected ({{ selected.length }})</a>

        <a href='#' v-if='anySelected && has_unarchive' @click.prevent="unarchive" class='button button--small button--secondary'>Unarchive selected ({{ selected.length }})</a>
      </div>
    </div>

    <slot></slot>
    <ul :class='{"index__list": true}'>
      <div class='index__loading' v-if='!loaded'><double-bounce></double-bounce></div>
      <li class='index__item' v-for='item in items' v-if='loaded'>
        <h2>
          <input v-if='can' v-model="selected" :value="item.id" type='checkbox'>
          <a :href="item.url">{{item.name}}<span class='draft-icon' v-if='item.draft'>Draft</span></a>
        </h2>
      </li>
    </ul>

    <div class='index__footer'>
      <p v-if='totalResults > 0'>Page {{currentPage}} of {{ numPages }} / {{totalResults}} total records</p>
      <div class='button-group' v-if='totalResults > 0'>
        <a href='#' class='button button--secondary' v-if='currentPage != 1' @click.stop.prevent="prevPage">Previous</a>
        <a href='#' class='button button--secondary' v-if='!lastPage' @click.stop.prevent="nextPage">Next </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Icon from "../components/icon.vue";
import {DoubleBounce} from 'vue-loading-spinner'

export default {
  props: ["url", "can", "record_type", "has_unarchive"],
  components: {
    Icon,
    DoubleBounce
  },
  created () {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    let page = params.get("page")
    if (page) {
      this.currentPage = parseInt(page);
    }
  },
  mounted () {
    var that = this;

    axios.get(that.url, {
      params: {
        page: that.currentPage
      }
    })
    .then(function (response) {
      that.loaded = true;
      that.items = response.data.records;
      that.totalResults = response.data.meta.total_results;
      that.perPage = response.data.meta.per_page;
    })
  },
  methods: {
    prevPage () {
      this.currentPage--;
      this.search(this.currentPage)

      this.setUrl('page', this.currentPage)
    },
    nextPage () {
      this.currentPage++;
      this.search(this.currentPage)
      this.setUrl('page', this.currentPage)
    },
    setUrl (name, value) {
      const params = new URLSearchParams(location.search);
      params.set(name, value);
      window.history.replaceState({}, '', `${location.pathname}?${params}`);
    },
    onInput () {
      var that = this;

      that.loaded = false;
      that.currentPage = 1
      that.search(that.currentPage)
    },
    search (page) {
      var that = this;
      axios.get(that.url, {
        params: {
          query: that.query,
          page: page
        }
      })
      .then(function (response) {
        that.loaded = true;
        that.items = response.data.records;
        that.totalResults = response.data.meta.total_results;
        that.perPage = response.data.meta.per_page;
      })
    },
    unarchive () {
      var that = this;

      if(confirm(`Are you sure you want to unarchive ${this.selected.length} records ?`)){
        that.$api.post('/unarchive_records.json', 
          {
            "record_type": that.record_type,
            "records": that.selected
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.selected.forEach(function (selected_value) {

            that.items.splice(that.items.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.selected = []

          console.log(response)
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
    archive () {
      var that = this;

      if(confirm(`Are you sure you want to archive ${this.selected.length} records ?`)){
        that.$api.post('/archive_records.json', 
          {
            "record_type": that.record_type,
            "records": that.selected
          },
          { 
            headers: {'Content-Type': 'application/json'}
          }).then(function (response) {

          that.selected.forEach(function (selected_value) {

            that.items.splice(that.items.findIndex(function(i){
                return i.id === selected_value;
            }), 1);

          })

          that.selected = []

          console.log(response)
          that.flash(response.data.msg, 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }).catch(error => {

          that.flash("Something went wrong!", 'error', 
            {
              timeout: 3000,
              important: true
            }
          );
          console.log(error)
        })
      }
    },
    selectAll () {
      var selected = [];

      this.items.forEach(function (item) {
        selected.push(item.id);
      });

      this.selected = selected;
    },
    deselectAll () {
      this.selected = [];
    }
  },

  computed: {
    numPages () {
      var totalPages = Math.ceil(this.totalResults / this.perPage);
      return totalPages == 0 ? 1 : totalPages; 
    },
    lastPage () {
      return this.currentPage === this.numPages ? true : false
    },
    allSelected () {
      return this.items ? this.selected.length == this.items.length : false;
    },
    anySelected () {
      return this.selected.length > 0 ? true : false;
    }
  },
  watch: {
    order (newOrder, oldOrder) {
      this.setUrl('order', newOrder);
      this.search(this.currentPage)
    },
  },
  data: function () {
    return {
      loaded: false,
      items: [],
      selected: [],
      page: 1,
      perPage: 0,
      currentPage: 1,
      totalResults: 0,
      query: ""
    }
  }
}
</script>

