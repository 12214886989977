<template>
  <div>
    <input type='hidden' name='alert[alert_type]' :value='alert_type'>
    <input type='hidden' name='alert[email]' :value='email'>
    <input type='hidden' name='alert[webhook_url]' :value='webhook_url'>
    <input type='hidden' name='alert[expiration_type]' :value='expiration_type'>
    <input type='hidden' name='alert[webhook_payload]' :value='webhook_payload'>
    <input type='hidden' name='alert[days_until]' :value='days_until'>

    <h3 style='margin-top: 0;'>What kind of alert is this?</h3>
    <div class='form-group'>
      <label class='required'>Event</label>
      <div class='roler__choices'>
        <label>
          <input value='expiration' v-model='alert_type' type='radio'>
          <h2>Expiration List</h2>
          <p>Receive a list when expirations hit a trigger number of days</p>
        </label>
        <label>
          <input value='website_monitor' v-model='alert_type' type='radio'>
          <h2>Website Down</h2>
          <p>Receive a notification when a website appears down</p>
        </label>
        <label>
          <input value='password_view' v-model='alert_type' type='radio'>
          <h2>Shared Password Reveal</h2>
          <p>Receive an alert when a shared password has been revealed</p>
        </label>
        <label>
          <input value='password_change' v-model='alert_type' type='radio'>
          <h2>Password Created/Updated</h2>
          <p>Receive an alert when a password has been created or updated</p>
        </label>
      </div>
    </div>

    <div class='form-group'>
      <div v-if='alert_type == "expiration"' class='roler__help'>
        <h1>Variables you can use in webhooks:</h1>
        <p><b>$EXPIRATIONS_COUNT</b> = Number of expirations</p>
        <p><b>$EXPIRATIONS_HUDU_URL</b> = Link to the relevant expirations in Hudu</p>
        <p><b>$TRIGGER_DAYS</b> = Number of days set as trigger time</p>
      </div>
      <div v-if='alert_type == "website_monitor"' class='roler__help'>
        <h1>Variables you can use in webhooks:</h1>
        <p><b>$WEBSITE_NAME</b> = Name of website</p>
        <p><b>$WEBSITE_HUDU_URL</b> = Link to the relevant URL in Hudu</p>
      </div>
      <div v-if='alert_type == "password_view"' class='roler__help'>
        <h1>Variables you can use in webhooks:</h1>
        <p><b>$RECORD_NAME</b> = Name of password</p>
        <p><b>$HUDU_URL</b> = Link to the relevant URL in Hudu</p>
      </div>
      <div v-if='alert_type == "password_change"' class='roler__help'>
        <h1>Variables you can use in webhooks:</h1>
        <p><b>$RECORD_NAME</b> = Name of password</p>
        <p><b>$HUDU_URL</b> = Link to the relevant URL in Hudu</p>
        <p><b>$ACTION</b> = Name of action that occurred (create or update)</p>
      </div>
    </div>

    <div v-if='alert_type == "expiration"'>

      <div class='form-group'>
        <label for='days_until' class='required'>Trigger Alert X days before Expiring</label>
        <input type='number' v-model='days_until' placeholder="30" min=0>
      </div>

      <div class='form-group'>
        <label for='expiration_type' class='required'>Expiration Type</label>
        <select v-model='expiration_type'>
          <option value='all_unexpired'>Alert for All Expirations</option>
          <option value='domain'>Alert for only Domain Expirations</option>
          <option value='ssl_certificate'>Alert for only SSL Certificate Expirations</option>
          <option value='warranty'>Alert for only Warranty Expirations</option>
          <option value='article_expiration'>Alert for only Article Expirations</option>
          <option value='asset_field'>Alert for only Asset Expirations</option>
        </select>
      </div>
    </div>

    <h3 style='margin-top: 0;'>Where should this alert be sent?</h3>

    <div class='form-group'>
      <label for='email'>Email Address</label>
      <input type='email' v-model='email' placeholder='example@example.com'>
    </div>

    <div class='form-group'>
      <label for='webhook_url'>Webhook URL</label>
      <input type='text' v-model='webhook_url'>
    </div>

    <div class='form-group'>
      <label for='webhook_payload'>Webhook Payload</label>
      <textarea placeholder="Must be valid JSON" v-model='webhook_payload'></textarea>
    </div>

    <div class='alert'><p><i class='fas fa-question-circle'></i> Need help with webhooks? Here are guides setting them up for <a target="_blank" href="https://usehudu.zendesk.com/hc/en-us/articles/360044642693">Slack</a>, <a target="_blank" href="https://support.usehudu.com/hc/en-us/articles/360046283553">Teams</a>, <a target="_blank" href="https://support.usehudu.com/hc/en-us/articles/360044147154">Discord</a>, and a <a target="_blank" href="https://support.usehudu.com/hc/en-us/articles/360044147294">generic guide</a></p>.</div>

  </div>
</template>

<script>

export default {
  props: ["prealert_type", "prewebhook_url", "preemail", "prewebhook_payload", "preexpiration_type", "predays_until"],
  data: function () {
    return {
      alert_type: this.prealert_type || "expiration",
      email: this.preemail || "",
      webhook_url: this.prewebhook_url || "",
      webhook_payload:  this.prewebhook_payload || "",
      expiration_type: this.preexpiration_type || "all_unexpired",
      days_until: this.predays_until || ""
    }
  }
}
</script>