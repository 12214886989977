<template>
  <div class='button-group'>
    <flash-message></flash-message>

    <a title='Open Website' v-if='website_url' target='_blank' :href='website_url' class='button button--secondary button--small'><i class='fas fa-external-link-alt'></i></a>
    <a href='#' title='Copy Username' v-if='username' @click.prevent.stop="copyUsername" class='button button--secondary button--small'><i class='fas fa-user'></i></a>
    <a href='#' title='Copy Password' @click.prevent.stop="copyPassword" class='button button--secondary button--small'><i class='fas fa-key'></i></a>
  </div>
</template>

<script>
const axios = require('axios');

export default {
  props: ["url", "username", "website_url"],
  data: function () {
    return {
      password: ""
    }
  },
  methods: {
    copyUsername (e) {
      var that = this;
      that.setClipboard(that.username, e, "Copied Username!")
    },
    copyPassword (e) {

      var that = this;

      if(that.password == "") {
        var xhttp = new XMLHttpRequest();
        xhttp.open("GET", that.url, false);
        xhttp.send();
        var response = JSON.parse(xhttp.responseText);
        that.setClipboard(response["password"], e, "Copied Password!")
      } else {
        that.setClipboard(that.password, e, "Copied Password!")
      }
    },
    setClipboard(value, e, message) {
      var that = this;

      Clipboard.copy(value);
      that.isCopied = true;


      setTimeout(function(){ that.isCopied = false; }, 2000);

      that.flash(message, 'success', 
        {
          timeout: 3000,
          important: true
        }
      );

      // navigator.permissions.query({
      //   name: 'clipboard-write'
      // }).then(permissionStatus => {
      //   console.log(permissionStatus.state);

      //   permissionStatus.onchange = () => {
      //     console.log(permissionStatus.state);
      //   };
      // });

      // navigator.clipboard.writeText(value)
      // .then(() => {
      //   that.isCopied = true;
      // })
      // .catch(err => {
      //   console.log("error copying")
      // });
    }
  },
}


window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

