<template>



  <div class="wizard__field-box" :id="'field' + index">
    <section class="title"><h2>{{ asset_layout_field.label}}</h2></section>
    <section>
      <div class='form-group form-group--horizontal'>
        <label :for='"label" + index' class='required'>Label</label>
        <input type='text' :id='"label" + index' v-model='asset_layout_field.label'>
      </div>
    </section>
    <section>
      <div class='form-group form-group--horizontal'>
        <label :for='"type" + index' class='required'>Type</label>
        <select :id='"type" + index' v-model='asset_layout_field.field_type'>
          <option value="Text">Plain Text</option>
          <option value="RichText">Rich Text</option>
          <option value="Heading">Heading</option>
          <option value="CheckBox">Check box</option>
          <option value="Website">Website/IP Address</option>
          <option value="Password">Encrypted Password</option>
          <option value="Number">Integer</option>
          <option value="Date">Date</option>
          <option value="Dropdown">Dropdown</option>
          <option value="Embed">Embed HTML</option>
          <option value="Email">Copyable Text</option>
          <option value="Phone">Phone number</option>
          <option value="AssetTag">Link to multiple Assets</option>
          <option value="AssetLink">Link to single Asset</option>
        </select>
      </div>
    </section>

    <section>
      <div class='form-group' v-show='asset_layout_field.field_type == "AssetLink" || asset_layout_field.field_type == "AssetTag" '>
        <label :for='"pullfrom" + index' class='required'>Pull From:</label>
        <select :id='"pullfrom" + index' v-model='asset_layout_field.linkable_id'>
          <option v-for="al in asset_layouts" :value="al.id">{{al.name}}</option>
        </select>
      </div>
      <input type='hidden' v-model='asset_layout_field.linkable_type'>

      <div class='form-group-half' v-show='asset_layout_field.field_type == "Number"'>
        <div class='form-group' >
          <label :for='"min" + index'>Min.</label>
          <input :id='"min" + index' type='number' v-model='asset_layout_field.min'>
        </div>
        <div class='form-group' >
          <label :for='"max" + index'>Max.</label>
          <input :id='"max" + index' type='number' v-model='asset_layout_field.max'>
        </div>
      </div>

      <div class='form-group' v-show='asset_layout_field.field_type != "Heading"'>
        <label :for='"hint" + index'>Hint</label>
        <input :for='"hint" + index' type='text' v-model='asset_layout_field.hint'>
        <span class='hint'>Provide additional information underneath an input. (ex: This is a hint!)</span>
      </div>

      <div class='form-group' v-show='asset_layout_field.field_type == "Date"'>
        <label :for='"expiration" + index'>Add to Expirations</label>
        <input type='checkbox' :id='"expiration" + index' v-model='asset_layout_field.expiration'>
      </div>

      <div class='form-group' v-show='asset_layout_field.field_type != "Heading" && asset_layout_field.field_type != "CheckBox"'>
        <label :for='"required" + index'>Make Field Required</label>
        <input type='checkbox' :id='"required" + index' v-model='asset_layout_field.required'>
      </div>

      <div class='form-group' v-show='asset_layout_field.field_type == "Dropdown"'>
        <label :for='"options" + index' class='required'>Options</label>
        <textarea :id='"options" + index' v-model='asset_layout_field.options'></textarea>
        <span class='hint'>One option per line</span>
      </div>

      <div>
        <a href='#' class='button button--danger button--small' @click.prevent="removeField(index)">Delete Field</a>
      </div>
    </section>
   </div>
  </template>

  <script>
      export default {
          props: ["asset_layout", "asset_layout_field", "asset_layouts", "index"],
          name: "field",
          methods: {
              removeField (index) {

                  if(confirm("Do you really want to delete? This action is permanent and can result in loss of data!!")){
                      this.asset_layout.fields[index].is_destroyed = true
                  }
              },
          }
      }
  </script>

  <style scoped>

  </style>