<template>
  <div class='writer-wrap'>
    <textarea :name='name' :id="'editor' + id" v-html='precontent'>
    </textarea>
  </div>
</template>

<script>
import '../lib/editor';
export default {
  props: ["precontent", "name", "value", "simple", "id", "record_id", "record_type"],
  components: {
    
  },
  mounted () {
    var that = this;
    ClassicEditor
      .create( document.querySelector( `#editor${that.id}` ),
        {
          simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: `/public_photos?record_id=${that.record_id}&record_type=${that.record_type}`,

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRF-TOKEN': 'CSFR-Token',
                Authorization: 'Bearer <JSON Web Token>'
            }
          }
        })
      .then( editor => {
          console.log( editor );
      } )
      .catch( error => {
          console.error( error );
      } );
  },
  methods: {
  },
  data() {
    return {
    }
  }
}
</script>

<style lang='scss'>
</style>

