<template>
  <div class='asset-sidebar__group' v-if='(can_create || uploads.length > 0)' @keydown.esc="close">
    <flash-message></flash-message>
    <header>
      <h2><i class='fas fa-file icon'></i>Files</h2>
    </header>
    <div v-show='can_create'>
      <vue-dropzone ref="myVueDropzone" id="dropzone" @vdropzone-success="vsuccess" :options="dropzoneOptions"></vue-dropzone>
    </div>
    <div class='asset-sidebar__box' v-if='uploads.length > 0'>
      <section v-for="(upload, index) in uploads">

        <template v-if='isEdit && (editIndex === index)'>
          <form @submit.prevent.stop='saveEdit(upload.id)' class='asset-sidebar__form'>
            <input type='text' v-model='newName' :id='"fileManagerInput" + index' placeholder="New name...">
            <div class='button-group'>
              <a href='#' @click.prevent.stop='saveEdit(upload.id)' class='button button--small button--primary'>Save</a>
              <a href='#' @click.prevent.stop='cancelEdit' class='button button--small button--secondary'>Cancel</a>
            </div>
          </form>
        </template>

        <template v-if='editIndex != index'>
          <h3>
            <small class='asset-sidebar__filetype'>{{upload.ext}}</small>
            <template v-if='upload.ext == "pdf"'>
              <a href='#' @click.prevent.stop='open(index)'>{{upload.name}}</a>
            </template>
            <template v-if='upload.ext != "pdf"'>
              <a :href='upload.url'>{{upload.name}}</a>
            </template>
          </h3>
          <div v-if='can_create && upload.id' class='button-group'>
            <a href='' @click.prevent.stop='startEdit(index, upload.name)' class='button button--small button--secondary'><i class="fas fa-edit"></i></a>
            <a href='#' @click.prevent="deleteupload(upload.id, index)" class='button button--danger button--small'><i class='fas fa-trash'></i></a>
          </div>
        </template>
      </section>

      <div class='photo-manager__bg' v-if='showIndex != -1'>
        <div class='photo-manager__modal' v-click-outside="close">
          <header>
            <h3>{{uploads[showIndex].name}}</h3>
            <a href='#' class='close' @click.prevent.stop='close'><i class='fas fa-times'></i></a>
          </header>
          <main>
            <embed :src="uploads[showIndex].url + '?inline=true'" type="application/pdf" width="100%" height="750px">
          </main>
          <aside></aside>
          <footer>
            <a :href='uploads[showIndex].url' class='button button--secondary'><i style='margin-right: 1rem' class='fas fa-download icon'></i>Download</a>
          </footer>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import flash from "../lib/flashes.js";
import vClickOutside from 'v-click-outside'

export default {
  props: ["base_url", "type", "show",
          "url", "uploadable_id", "uploadable_type",
          "can_create", "max_file_size"],
  components: {
    vueDropzone: vue2Dropzone
  },
  mounted () {

    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.uploads = data.uploads;
    })
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    vsuccess ( file, response) {
      var that = this;

      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.uploads = data.uploads;
        flash('File Added!','success')
      })
    },
    startEdit (index, name) {
      this.isEdit = true;
      this.editIndex = index;
      this.newName = name;

      this.$nextTick(() => document.querySelector("#fileManagerInput" + index).focus())
    },
    addFile () {
      if (this.add === true) {
        this.add = false;
        return;
      }
      this.add = true
    },
    isImage(mime) {
      return false
    },
    deleteupload (id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.base_url}/uploads/${id}`).then(function (response) {
          that.uploads.splice(index, 1);
        }).catch(error => {
          console.log(error)
        })
      }
    },
    cancelEdit () {
      this.editIndex = -1;
      this.newName = "";
      this.isEdit = false;
    },
    clearEdit () {
      this.editIndex = -1;
      this.newName = "";
      this.isEdit = false;
    },
    saveEdit (id) {
      var that = this;
      that.$api.put(`${that.base_url}/uploads/${id}`, { name: that.newName}).then(function (response) {
        fetch(that.url)
        .then((resp) => resp.json())
        .then(function(data) {
          that.uploads = data.uploads;
          flash('File Updated!','success')
          that.clearEdit()
        })
      }).catch(error => {
        console.log(error)
      })
    },
    close () {
      this.showIndex = -1;
    },
    open (index) {
      this.showIndex = index;
    }
  },
  data: function () {
    return {
      dropzoneOptions: {
          url: '/uploads',
          timeout: 90000,
          parallelUploads: 10,
          thumbnailWidth: 150,
          maxFilesize: this.max_file_size,
          params: {
            uploadable_id: this.uploadable_id,
            uploadable_type: this.uploadable_type
          }
      },
      isEdit: false,
      add: false,
      editIndex: -1,
      newName: "",
      uploads: [],
      isShown: true,
      showIndex: -1
    }
  },
  filters: {
    prettyBytes (num) {
      // jacked from: https://github.com/sindresorhus/pretty-bytes
      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
      }

      var exponent;
      var unit;
      var neg = num < 0;
      var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
      }

      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? '-' : '') + num + ' ' + unit;
    }
  }
}
</script>

