<template>
  <div class='asset-sidebar__group asset-sidebar__group--photo-manager' @keydown.esc="close">
    <flash-message></flash-message>
    <header>
      <h2><i class='fas fa-image icon'></i>Photos</h2>
      <a href='#' v-if='can_create' @click.prevent.stop='paste($event)' class='paste'><i class="fas fa-paste"></i>Paste</a>
    </header>
    <div v-show='can_create'>
      <vue-dropzone ref="myVueDropzone" id="dropzone" @vdropzone-success="vsuccess" :options="dropzoneOptions"></vue-dropzone>
    </div>
    <div class='photo-manager' v-if='photos.length > 0'>
      <a href='#' @click.prevent.stop='open(index)' v-for='(photo, index) in photos' class='photo-manager__img'>
        <vue-load-image>
          <img slot="image" :src='photo.url' />
          <loader slot='preloader'/>
          <div slot="error" class='photo-manager__no'><i class='fas fa-image'></i></div>
        </vue-load-image>
        <span>{{photo.caption}}</span>
      </a>
    </div>

    <div class='photo-manager__bg' v-if='active != -1'>
      <div class='photo-manager__modal' v-click-outside="close">
        <header>
          <h3>{{photos[active].caption}}</h3>
          <a href='#' class='close' @click.prevent.stop='close'><i class='fas fa-times'></i></a>
        </header>
        <main>
          <vue-load-image>
            <img slot="image" :src='photos[active].original_url' />
            <loader slot='preloader'/>
            <div slot="error" class='photo-manager__no photo-manager__no--lg'><i class='fas fa-image'></i></div>
          </vue-load-image>
        </main>
        <aside></aside>
        <footer >
          <template v-if='can_create'>
            <form @submit.prevent.stop='saveEdit' v-if='isEdit'>
                <input v-model='newCaption' type='text' id='photoManagerInput' placeholder='Enter caption here...'>
                <a href='#' class='button button--primary' @click.prevent.stop='saveEdit'>Save</a>
                <a href='#' class='button button--secondary' @click.prevent.stop='cancelEdit'>Cancel</a>
              </form>
            <a href='#' v-if='!isEdit' class='button button--secondary' @click.prevent.stop='startEdit'><i class='fas fa-edit'></i>Edit</a>

            <a href='#' @click.prevent.stop='deletePhoto(photos[active].id, active)' class='button button--danger'><i class='fas fa-trash'></i>Delete</a>
          </template>
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import VueLoadImage from 'vue-load-image'
import vClickOutside from 'v-click-outside'
import Loader from './loader.vue'
import flash from "../lib/flashes.js";

export default {
  props: ["base_url", "type", "show",
          "url", "photoable_id", "photoable_type",
          "can_create", "max_file_size"],
  components: {
    vueDropzone: vue2Dropzone,
    loader: Loader,
    'vue-load-image': VueLoadImage
  },
  mounted () {

    var that = this;

    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.photos = data.photos;
    })

    document.addEventListener("keyup", this.handleArrows);
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    paste (event) {

      getClipboardContents()

      var allowedFileTypes = ["image/png", "image/jpeg", "image/gif"];
      var that = this;
      async function getClipboardContents() {
        try {
          const clipboardItems = await navigator.clipboard.read();
          for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
              const blob = await clipboardItem.getType(type);

              if (allowedFileTypes.indexOf(blob.type) > -1) {

                var createdFileName = ""

                if (blob.type == "image/png") {
                  createdFileName = "pastedPhoto.png";
                } else if (blob.type == "image/jpeg") {
                  createdFileName = "pastedPhoto.jpeg";
                } else {
                  createdFileName = "pastedPhoto.gif";
                }

                
                var fileOfBlob = new File([blob], createdFileName);
                that.$refs.myVueDropzone.addFile(fileOfBlob)
              } else {
                flash('Unsupported photo format','error')
              }
            }
          }
        } catch (err) {
          flash('Your browser does not support this :(','error')
        }
      }
    },
    handleArrows () {
      if (event.keyCode == 37 && this.active > 0) {
        this.active--
      } else if (event.keyCode == 39 && this.active < (this.photos.length - 1)) {
        this.active++
      }
    },
    vsuccess ( file, response) {
      var that = this;

      fetch(that.url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.photos = data.photos;
        flash('Photo Added!','success')
      })
    },
    startEdit (index, name) {
      this.isEdit = true;
      this.$nextTick(() => document.querySelector("#photoManagerInput").focus())
    },
    saveEdit () {
      var that = this;
      var id = this.photos[this.active].id
      that.$api.put(`${that.base_url}/photos/${id}`, { caption: that.newCaption}).then(function (response) {
        that.isEdit = false;
        that.newCaption = "";
        fetch(that.url)
        .then((resp) => resp.json())
        .then(function(data) {
          that.photos = data.photos;
          flash('Photo Updated!','success')
        })
      }).catch(error => {
        console.log(error)
      })
    },
    deletePhoto (id, index) {
      var that = this;

      if(confirm('Are you sure?')) {
        that.$api.delete(`${that.base_url}/photos/${id}`).then(function (response) {
          that.close()
          that.photos.splice(index, 1);

          flash('Photo Deleted!','success')
        }).catch(error => {
          console.log(error)
        })
      }
    },
    cancelEdit () {
      this.newCaption = "";
      this.isEdit = false;
    },
    close () {
      this.active = -1
    },
    open (index) {
      this.active = index;
    }
  },
  data: function () {
    return {
      dropzoneOptions: {
          url: '/photos',
          parallelUploads: 10,
          acceptedFiles: ".jpeg,.jpg,.png,.gif",
          maxFilesize: this.max_file_size,
          dictDefaultMessage: "Drop photos here to upload",
          params: {
            photoable_id: this.photoable_id,
            photoable_type: this.photoable_type
          }
      },
      isEdit: false,
      add: false,
      active: -1,
      editIndex: -1,
      newCaption: "",
      photos: [],
      isShown: true
    }
  },
  filters: {
    prettyBytes (num) {
      // jacked from: https://github.com/sindresorhus/pretty-bytes
      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number');
      }

      var exponent;
      var unit;
      var neg = num < 0;
      var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B';
      }

      exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? '-' : '') + num + ' ' + unit;
    }
  }
}
</script>

