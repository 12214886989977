<template>
  <div class='preview-group'>
    <div class='preview'>
      <h2>Preview:</h2>

      <div class='asset-icon big' :style='bgStyle'>
        <i :class='asset_layout.icon' :style="iconColorStyle"></i>
      </div>
    </div>

    <div>
      <div class='form-group-half'>
        <div class='form-group'>
          <label for='' class='required'>Main color</label>
          <input type='color' id='name' v-model='asset_layout.color'>
        </div>
        <div class='form-group'>
          <label for='' class='required'>Icon color</label>
          <input type='color' id='name' v-model='asset_layout.icon_color'>
        </div>
      </div>
    </div>

    <div class='preview__icons'>
      <label :for="'icon' + index" v-for='(i, index) in icons' :class='{"selected": i == asset_layout.icon }'>
        <i :class='i'></i>
        <input type="radio" :id="'icon' + index" :value='i' v-model='asset_layout.icon'>
      </label>
    </div>
  </div>
</template>

<script>
    import icons from '../../lib/icons.json';
    export default {
        props: ["asset_layout"],
        name: "design",
        data () {
            return {
                icons: icons.icons,
            }
        },
        computed: {
            borderStyle () {
                return "border: 1px solid " + this.asset_layout.color
            },
            bgStyle () {
                return "background: " + this.asset_layout.color
            },
            iconColorStyle () {
                return "color: " + this.asset_layout.icon_color
            }
        }
    }
</script>

<style scoped>

</style>