
import flash from "../../lib/flashes.js";

const state = {
  showEdit: false,
  showSort: false,
  showBulk: false,
  showDescription: false,
  bulk: "",
  openProcessView: -1,
  openProcessEdit: -1,
  procedure: {
    name: "",
    description: "",
    procedure_tasks_attributes: []
  },
  deleted: []
}

const getters = {
  tasksLength (state) {
    return state.procedure.procedure_tasks_attributes.filter(task => !task._deleted).length
  }
}

const actions = {
  setState ({ commit, state}, payload) {
    commit('SET_STATE', payload)
  },
  toggleEdit ({commit, state}) {
    commit('TOGGLE_EDIT')
  },
  toggleDescription ({commit, state}) {
    commit('TOGGLE_DESCRIPTION')
  },
  toggleOpenProcessView ({commit, state}, payload) {
    commit('TOGGLE_OPEN_PROCESS_VIEW', payload)
  },
  toggleOpenProcessEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_PROCESS_EDIT', payload)
  },
  toggleBulk ({commit, state}) {
    commit('TOGGLE_BULK')
  },
  toggleSort ({commit, state}) {
    commit('TOGGLE_SORT')
  },
  convertBulk ({commit, state}) {
    commit('CONVERT_BULK');
  },
  updateProcedureName ({commit, state}, payload) {
    commit('UPDATE_PROCEDURE_NAME', payload)
  },
  updateProcedureDescription ({commit, state}, payload) {
    commit('UPDATE_PROCEDURE_DESCRIPTION', payload)
  },
  updateBulk ({commit, state}, payload) {
    commit('UPDATE_BULK', payload)
  },
  updateTaskName ({commit, state}, payload) {
    commit('UPDATE_TASK_NAME', payload)
  },
  update_tasks ({commit, state}, value) {
    commit('UPDATE_TASKS', value)
  },
  fetch ({ commit, state}) {
    this._vm.$api.get(`${state.url}.json`).then(function (response) {
      commit('SET_PROCEDURE', response.data)
    })
    .catch(function (response) {
      flash("Something went wrong!", 'error')
    })
  },
  check ({commit, state}, payload) {
    var id = payload[0]
    var index = payload[1]
    var completed = payload[2]

    if (completed) {
      this._vm.$api.put(`${state.url}/uncheck`, { 
        task_id: id
      }).then(function (response) {
        commit('SET_PROCEDURE', response.data)
      })
    } else {
      this._vm.$api.put(`${state.url}/check`, { 
        task_id: id
      }).then(function (response) {
        commit('SET_PROCEDURE', response.data)
      })
    }
  },
  addTask ({commit, state}) {
    commit('ADD_TASK')
  },
  removeTask ({commit, state}, payload) {
    commit('REMOVE_TASK', payload)
  },
  resort ({commit, state}) {
    commit('RESORT')
  },
  submitForm ({commit, state}) {
    var newProcedure = state.procedure;

    state.deleted.forEach(function(item){
      newProcedure.procedure_tasks_attributes.push({
        id: item,
        name: "",
        _destroy: true
      })
    })

    this._vm.$api.put(state.url, { 
      procedure: newProcedure
    }).then(function (response) {
      commit('HANDLE_SUBMIT_SUCCESS', response.data)
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response; 
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, 'error')
    })
  },
  updateTaskDescription ({commit, state}, payload) {
    commit('UPDATE_TASK_DESCRIPTION', [payload[0], payload[1]])
  }
}

const mutations = {
  SET_STATE (state, payload) {
    state.url = payload[0]
    state.can = payload[1]
    state.template = payload[2]
  },
  UPDATE_TASK_DESCRIPTION (state, payload) {
    var index = payload[0]
    var description = payload[1]
    state.procedure.procedure_tasks_attributes[index].description = description
  },
  UPDATE_BULK (state, payload) {
    state.bulk = payload
  },
  TOGGLE_DESCRIPTION (state, payload) {
    state.showDescription = !state.showDescription
  },
  TOGGLE_OPEN_PROCESS_VIEW (state, payload) {
    console.log("-------")
    console.log(state.openProcessView)
    console.log("-")
    console.log(payload)
    if (state.openProcessView == "") { // If none are open, then open
      state.openProcessView = payload
    } else {
      state.openProcessView = payload
    }
    
  },
  TOGGLE_OPEN_PROCESS_EDIT (state, payload) {
    if (state.openProcessEdit == "") { // If none are open, then open
      state.openProcessEdit = payload
    } else if (state.openProcessEdit == payload) {
      state.openProcessEdit = ""
    } else {
      state.openProcessEdit = payload
    }
    
  },
  UPDATE_TASK_NAME (state, payload) {
    var index = payload[0]
    var value = payload[1]
    state.procedure.procedure_tasks_attributes[index].name = value
  },
  TOGGLE_EDIT (state) {
    state.showEdit = !state.showEdit;
    state.showBulk = false;
    state.openProcessEdit = -1
    state.openProcessView = -1
  },
  TOGGLE_BULK (state) {
    state.showBulk = !state.showBulk;
    state.openProcessEdit = -1
    state.openProcessView = -1
  },
  TOGGLE_SORT (state) {
    state.showSort = !state.showSort;
    state.openProcessEdit = -1
    state.openProcessView = -1
  },
  UPDATE_TASKS (state, value) {
    state.procedure.procedure_tasks_attributes = value;
  },
  CONVERT_BULK (state) {
    var items = state.bulk.replace(/\n\s*\n/g, '\n').split("\n");
    var items_length = items.length
      
    items.forEach( (element, index) => {
      state.procedure.procedure_tasks_attributes.push({
        id: "",
        name: element,
        position: index + 1,
        description: "",
        _destroy: ""
      })
    });

    state.showBulk = false;
    flash(`Added ${items_length} Tasks!`, 'success')
  },
  HANDLE_SUBMIT_SUCCESS (state, data) {
    state.procedure = data.procedure;
    state.showEdit = false;
    flash("Process Updated!", 'success')
  },
  UPDATE_PROCEDURE_NAME (state, value) {
    state.procedure.name = value
  },
  UPDATE_PROCEDURE_DESCRIPTION (state, value) {
    state.procedure.description = value
  },
  ADD_TASK (state) {
    state.procedure.procedure_tasks_attributes.push({
      id: "",
      name: "",
      position: state.procedure.procedure_tasks_attributes.length + 1,
      description: "",
      _destroy: false
    })
  },
  REMOVE_TASK (state, payload) {
    var index = payload[0]
    var id = payload[1]

    if (id) {
      state.deleted.push(id) // Add to deleted
    }

    state.procedure.procedure_tasks_attributes.splice(index, 1) // Remove from visible list

    state.procedure.procedure_tasks_attributes.forEach(function (item, index) {
      item.position = index + 1 // Make 
    })
  },
  RESORT (state) {
    state.procedure.procedure_tasks_attributes.forEach(function (item, index) {
      item.position = index + 1
    })
  },
  SET_PROCEDURE (state, data) {
    state.procedure = data.procedure
  }
  
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}